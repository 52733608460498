<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow-sm border border-3">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <small> Enter with your access </small>
          </div>
          <form role="form" @submit.prevent="handleSubmit()">
            <base-input
              alternative
              class="input-group-alternative mb-3"
              placeholder="Email"
              addon-left-icon="ni ni-email-83"
              v-model="email"
              name="Email"
            >
            </base-input>
            <validation-error :errors="apiValidationErrors.email" />

            <base-input
              alternative
              class="input-group-alternative"
              placeholder="Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="password"
              name="Password"
            >
            </base-input>
            <validation-error :errors="apiValidationErrors.password" />

            <base-checkbox class="custom-control-alternative">
              <span class="text-muted">Remember me</span>
            </base-checkbox>
            <div class="text-center">
              <base-button
                type="primary"
                native-type="submit"
                class="my-4"
                :disabled="load"
                >Enter</base-button
              >
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <router-link to="/password/reset" class="text-light"
            ><small>Forgot your password?</small></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
export default {
  name: "login",
  components: {
    ValidationError,
  },
  mixins: [formMixin],
  data: () => ({
    email: "",
    password: "",
    load: false,
  }),
  computed: {
    isAuthenticated: function () {
      return this.$store.getters.isAuthenticated();
    },
  },
  methods: {
    async handleSubmit() {
      this.load = true;
      const user = {
        username: this.email,
        password: this.password,
        grant_type: "password",
        client_secret: "50kmnTpYXXvMmRIIgBtSCZnIcly9zmNIIWdtCZKe",
        client_id: 2,
      };
      const requestOptions = {
        headers: {},
      };

      try {
        await this.$store.dispatch("login", { user, requestOptions });
        this.load = false;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: "Invalid credentials",
          horizontalAlign: "center",
        });
        this.load = false;
        // this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>
